@import '../vars_smart';

* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  min-width: 320px;
  min-height: 480px;
}

body {
  background-color: $content-bg-color;
}

ul,
li {
  list-style-type: none;
}

img {
  border: 0;
}

a,
span.link {
  color: #3366cc;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

a:hover,
a:focus,
a:active,
a:focus,
span.link:hover {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

table {
  border-collapse: collapse;
  width: 100%;
  
  tr {
    vertical-align: top;
  }
}

select {
  border: 1px solid gray;
  background: #fff;
}

select.form-control {
  height: 25px !important;
}

textarea {
  outline: none;
  resize: none;
  word-wrap: break-word;
}

h1 {
  line-height: 28px;
  padding: 11px 15px;
  font-weight: normal;
  margin: -15px -15px 15px -15px;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.wrap-h1 {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  
  &.with-webf-box {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  h1 {
    font-size: 22px;
    border-bottom: none;
    margin-bottom: 0;
  }
}

h3 {
  margin-top: 10px;
}

h4 {
  margin: 10px 0 5px;
  color: #555;
}

.separator {
  background-color: #ddd;
  margin: 20px 0;
  height: 1px;
  font-size: 0;
  
  &.xsmall {
    margin: 5px 0;
  }
  
  &.small {
    margin: 10px 0;
  }
}

/*-------------------------*\
    View
\*-------------------------*/

.line {
  margin-bottom: 10px;
}

label.intitule {
  font-weight: bold;
  margin: 0;
}

/*-------------------------*\
    Bootsrap override
\*-------------------------*/

label {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 10px;
}

.form-control {
  height: auto;
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 2px;
}

.input-group-addon {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  min-width: 40px;
}

label:first-child.required span:after,
label.label_in_first.required span:after {
  content: '*';
  margin-left: 3px;
  color: red;
}

.form-control-static {
  padding-top: 2px;
}

.form-control:focus {
  box-shadow: none;
  border-color: $bg-main-color;
}

.form-horizontal .control-label {
  padding-top: 3px;
}

.form-horizontal .form-group-inline {
  margin-left: 0;
  margin-right: 0;
}

/*-------------------------*\
    Header
\*-------------------------*/

header {
  font-family: $frame-font-family, sans-serif;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  
  nav {
    font-weight: 600;
    color: $topbar-text-color;
    font-size: 1.2em;
    
    .navbar-nav {
      margin: 0;
      
      & > li > a {
        padding: 15px;
        
        &.messagerie > .label {
          padding: 1px 5px;
          position: relative;
          top: -3px;
          background-color: $primary;
        }
        
        &.application-livy, &.application-360d {
          padding: 0;
          margin-right: 4px;
          margin-left: 4px;

          img {
            width: 40px;
            margin-top: 5px;
          }
        }
      }
    }
    
    .navbar-right {
      transition-duration: $duration-transition-sidebar;

      float: right;
      position: relative;
      left: 0;

      & > li {
        float: left;
      }
    }
    
    ul.nav > li > a {
      color: $topbar-text-color;
      
      &.toggle-user-menu {
        .intitule {
          display: none;
        }
      }
  
      &:hover,
      &:focus,
      &.open {
        background-color: transparent;
      }
    }
  }
}

.navbar-btn.sidebar-toggle {
  display: block;
  position: absolute;
  width: 40px;
  height: 30px;
  margin: 10px;
  background-color: darken($topbar-bg-color, 10%);
  border-radius: 3px;
  z-index: 10;
  left: 0;
  
  .icon-bar {
    transform: rotate(0);
    transition-duration: $duration-transition-sidebar;
    background-color: $topbar-text-color;
    border-radius: 3px;
    
    display: block;
    position: absolute;
    width: 16px;
    height: 3px;
    left: 12px;
    
    &:nth-child(2) {
      top: 8px;
    }
    &:nth-child(3),
    &:nth-child(4) {
      top: 14px;
    }
    &:nth-child(5) {
      top: 20px;
    }
  }
}

body.sidemenu-open {
  header {
    .logo {
      left: 0;
    }
  }
  
  .navbar-btn.sidebar-toggle {
    display: none;
  }
}

//----- Header theme -----//

.dropdown {
  margin-right: 15px;
}

.dropdown-menu-user {
  padding: 4px 0;
  font-family: $frame-font-family;
  border: 1px solid #e6e6e6;
  min-width: 150px;
  
  li.webf-list-item {
    &.webf-list-item-separator {
      background-color: #f1f0f0;
    }
    
    a {
      height: inherit;
      padding: 10px 15px;
      border: none;
      font-size: 1em;
      
      &:hover {
        background-color: #ecedee;
        color: #212529;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}

/*-------------------------*\
    containers
\*-------------------------*/

.main-container {
  position: relative;
  margin-bottom: -1px;
  
  &,
  & > .page-container {
    &,
    & > .right-side {
      &,
      & > .content-application {
        height: 100%;
      }
    }
  }
}

.right-side {
  transition-duration: $duration-transition-sidebar;
  
  &.fullsize {
    margin-left: 0;
  }
  
  .content-application {
    padding: 15px;
  }
}

.overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 8;
  background-color: transparent;
}

body.sidemenu-open .overlay {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

/*-------------------------*\
    left side
\*-------------------------*/

.left-side {
  transition-duration: $duration-transition-sidebar;
  position: fixed;
  top: 0;
  left: -$width-sidebar;
  width: $width-sidebar;
  height: 100%;
  z-index: 9;
  
  .logo {
    background-color: $sidebar-bg-color;
    font-size: 2em;
    line-height: 50px;
    text-align: center;
    z-index: 1;
    font-weight: 400;
    width: 100%;
    
    .nom-application {
      color: white;
      padding: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      display: block;
  
      .image-nom-application {
        width: 46px;
        margin-top: -6px;
      }
    }
    
    .logo-application {
      display: none;
      padding: 8px 4px;
      background-color: white;
      
      img {
        padding: 3px 0;
      }
    }
    
    .deuxi {
      color: $primary-bg-color;
    }
  }
  
  .sidebar-toggle {
    display: none;
    position: fixed;
    bottom: 0;
    text-align: center;
    background-color: $sidebar-bg-color;
    
    a {
      transition-duration: $duration-transition-sidebar;
      
      display: block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      
      .fa-angle-double-left {
        display: none;
      }
    }
  }
}

body.sidemenu-open .left-side {
  left: 0;
}

@media (min-width: 768px) {
  body.sidemenu-open {
    nav {
      margin-left: 0;
    
      & > .navbar-nav.navbar-right {
        left: 0;
      }
    }
    
    .right-side {
      margin-left: $width-sidebar;
    }
    
    .left-side {
      width: $width-sidebar;
    
      .logo {
        .nom-application {
          display: block;
          line-height: 50px;
        }
    
        .logo-application {
          display: none;
        }
      }
      
      .sidebar-toggle a {
        width: $width-sidebar;
      
        .fa-angle-double-left {
          display: inline;
        }
        .fa-angle-double-right {
          display: none;
        }
      }
    }
    
    .overlay {
      display: none;
    }
  }
  
  .navbar-btn.sidebar-toggle {
    display: none;
  }
  
  header {
    padding: 10px 0;
    
    nav {
      .navbar-nav.navbar-right:last-child {
        margin-right: 0;
      }
      
      & > .navbar-btn.sidebar-toggle {
        display: none;
      }
      
      ul.nav > li > a.toggle-user-menu .intitule {
        display: inline-block;
        padding-left: 10px;
      }
    }
  }
  
  .right-side {
    margin-left: 50px;
  }
  
  .left-side {
    left: 0;
    width: 50px;
    box-shadow: none;
  
    .logo {
      line-height: 0;
    
      .nom-application {
        display: none;
      }
    
      .logo-application {
        display: block;
      }
    }
    
    .sidebar-toggle {
      display: block;
    }
  }
}

@media (min-width: 992px) {
  body:not(.sidemenu-collapsed) {
    .left-side {
      width: $width-sidebar;
      left: 0;
  
      .logo {
        line-height: 50px;
  
        .nom-application {
          display: block;
        }
  
        .logo-application {
          display: none;
        }
      }
      
      .sidebar-toggle a {
        width: $width-sidebar;
        
        .fa-angle-double-left {
          display: inline;
        }
        .fa-angle-double-right {
          display: none;
        }
      }
    }
  
    .right-side {
      margin-left: $width-sidebar;
    }
    .overlay {
      display: none;
    }
  }
}

//----- Left side theme -----//

.left-side {
  font-family: $frame-font-family, sans-serif;
  background-color: $sidebar-bg-color;
  
  .sidebar-toggle > a {
    font-size: 1.5em;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.3);
    color: $sidebar-text-color;
    
    &:hover {
      border-right: 1px solid $border-color;
      background-color: $sidebar-text-color;
      color: $sidebar-bg-color;
    }
  }
}

/*-------------------------*\
    Pagination
\*-------------------------*/

.pagination {
  display: block;
  margin: 10px 0;
  
  & > li > span,
  & > li > a {
    border: none;
    border-radius: 5px !important;
    font-size: 0.9em;
    float: none;
  }
}

/*-------------------------*\
    CRUD
\*-------------------------*/

.list-entities {
  .entity {
    h3 a {
      color: #666;
    }
  }
}

.list-actions {
  margin-bottom: 10px;
}

/*-------------------------*\
    Divers
\*-------------------------*/

.smart-tooltip {
  opacity: 0.9;
  display: inline-block;
  background-color: white;
  color: #333;
  padding: 4px 8px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px #ddd;
  
  &.dark-tooltip {
    background-color: #555;
    color: #fff;
    box-shadow: none;
  }
  
  &.tooltip-down {
    &:before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      top: -6px;
      border: 6px solid transparent;
      border-bottom: 6px solid white;
      border-top: 0;
      margin-left: -6px;
    }
    
    &.dark-tooltip:before {
      border-bottom: 6px solid #555;
    }
  }
  
  &.tooltip-up {
    &:after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      bottom: -5px;
      border: 6px solid transparent;
      border-top: 6px solid white;
      border-bottom: 0;
      margin-left: -6px;
    }
    
    &.dark-tooltip:after {
      border-top: 6px solid #555;
    }
  }
  
  &.tooltip-agenda-event .content {
    max-width: 300px;
  }
}

.tooltip-event-agenda {
  position: relative;
  background: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.15);
  font-family: 'Lato', sans-serif;
  
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  &:after {
    border-color: transparent;
    border-top-color: #fff;
    border-width: 8px;
    margin-left: -8px;
  }
  
  &:before {
    border-color: transparent;
    border-top-color: #aaa;
    border-width: 9px;
    margin-left: -9px;
  }
}

.errors-form {
  display: none;
  background: #ffffdd !important;
  border: 1px solid #ffd700;
  padding: 5px 10px;
  color: #444556;
  margin-bottom: 10px;
}

.smart-alert {
  background-color: $primary-bg-color;
}

.webf-box {
  border: none;
  
  &.fold {
    .box-body {
      display: none;
    }
  }
  
  & > .box-header.drop {
    cursor: pointer;
  }
}

.smart-date,
.smart-datetime {
  cursor: default !important;
  background-color: #fff;
}

.webf-dialog.dialog-loading {
  width: auto;
  border: none;
  background: transparent;
  box-shadow: none;
  
  .wrap-img-dialog-wait {
    width: 120px;
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    border-radius: 75px;
  }
}

.webf-alert-wrapper {
  z-index: 2000;
}

@keyframes dots {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.dots-loading span {
  animation-name: dots;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.dots-loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots-loading span:nth-child(3) {
  animation-delay: 0.4s;
}

.webf-button {
  font-size: 1em;
}

.webf-select .select {
  font-size: 0.9em;
}

.webf-list .webf-list-item > a, .webf-list .webf-list-item > label {
  font-size: 0.9em;
}

/*-------------------------*\
    Listes
\*-------------------------*/

.box-list {
  .count {
    font-size: 22px;
    color: black;
  }
  
  .options-affichage {
    label {
      display: inline-block;
      float: left;
      margin-right: 4px;
      text-align: right;
      height: 23px;
      line-height: 23px;
    }
    
    .wrap-select-tri {
      width: 100%;
      margin-right: 10px;
      display: inline-block;
      
      select {
        width: 100%;
      }
      
      .select-tri {
        height: 23px;
        float: left;
        line-height: 15px;
        
        .select {
          .handle-down {
            padding: 1px 5px;
          }
        }
      }
    }
    
    .wrap-spinner-nb-par-page {
      label {
        height: 21px;
        line-height: 21px;
      }
      
      .nb-par-page {
        width: 50px;
        text-align: center;
      }
      
      .webf-spinner-buttons {
        a.webf-spin-btn.up {
          i.webf {
            top: -4px;
          }
        }
        
        a.webf-spin-btn.down {
          i.webf {
            top: -6px;
          }
        }
      }
    }
  }
}

/*----------------------------*\
    Formulaire de recherche
\*----------------------------*/
.form-search {
  margin-bottom: 12px;
  
  .webf-select {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  
  .padding-control {
    padding-bottom: 4px;
  }
  
  .webf-tagsinput {
    line-height: 15px;
    
    & > .webf-tag {
      padding: 4px 5px;
    }
    
    input[type='text'] {
      font-size: 12px;
    }
  }
  
  .form-group {
    margin-bottom: 4px;
  }
}

.webf-checkboxwrapper {
  position: relative;
  top: -1px; // dans les recherches c'est décalé sinon, dans la saisie des infos DPE aussi
}

.checkbox-type .webf-checkboxwrapper {
  top: 2px; // commande EDL sans ça, ça décale vers le haut
}

.webf-timepicker {
  table tr td {
    a.up,
    a.down {
      cursor: pointer;
      padding: 0;
      margin: 5px 0;
    }
  }
}

.wrap-recherche-adresse {
  margin-bottom: 22px;
}

/*------------------*\
    Progress bars
\*------------------*/
.photo .progressbar {
  display: inline-block;
  vertical-align: middle;
  height: 6px;
  width: 100%;
  
  &.progress-photo {
    width: 85%;
  }
}

/*---------*\
    Maps
\*---------*/

.dialog-map {
  .content-dialog-map {
    width: 100%;
    
    .map {
      height: 380px;
      width: auto;
    }
  }
}

/*---------*\
Média Queries
\*---------*/

@media (min-width: 991px) {
  .content-dialog-map {
    width: 700px;
  }
}

@media (min-width: 1200px) {
  .right-side {
    .content-application:not(.pleine-largeur) {
      width: 950px;
    }
  }
}

@media (min-width: 1400px) {
  .right-side {
    .content-application:not(.pleine-largeur) {
      width: 1150px;
    }
  }
}

@media (min-width: 1600px) {
  .right-side {
    .content-application:not(.pleine-largeur) {
      width: 1250px;
    }
  }
}

@media (max-width: 767px) {
  .wrap-h1 {
    .button-back,
    h1 {
      text-align: center;
      padding-bottom: 15px;
    }
  }
  
  .webf-box {
    .box-footer {
      button[type='submit'] {
        width: 100%;
      }
    }
  }
  
  .wrap-form .webf-checkboxwrapper {
    top: 0; // commande EDL sans ça, ça décale vers le haut
  }
}
